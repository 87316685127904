<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              v-if="false"
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              PesaSave
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="d-flex text-xl font-weight-semibold text--primary mb-2 center align-center justify-center">
            Hi, welcome back! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account.
          </p>
        </v-card-text>

        <!-- login using email -->
        <v-card-text v-if="loginWithEmail">
          <v-form>
            <v-text-field
              v-model="form.body.username"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="form.body.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-alert
              v-for="error in form.errors"
              :key="error"
              :value="true"
              type="error"
              class="mb-3"
            >
              <small v-if="loginWithEmail"> {{ error[0] }}</small>
            </v-alert>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                v-model="form.remember"
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <router-link
                to="/forgot-password"
                class="mt-1"
              >
                Forgot Password?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="login"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!--Login with phone number-->
        <v-card-text v-if="!loginWithEmail">
          <v-alert
            v-if="error"
            :value="true"
            type="error"
            class="mb-3"
          >
            <small v-if="!loginWithEmail"> {{ error }}</small>
          </v-alert>
          <v-form>
            <div v-if="!otp_sent">
              <VuePhoneNumberInput
                v-model="phone"
                outlined
                clearable
                :default-country-code="defaultCountryCode"
                :only-countries="['UG']"
                hide-details
                class="mb-3"
                @update="isPhoneNumberValid"
              />
              <v-btn
                block
                :disabled="!validPhoneNumber"
                color="primary"
                class="mt-6"
                :loading="loading"
                @click="loginWithPhoneNumber"
              >
                Send Otp Code
              </v-btn>
            </div>
            <div v-if="otp_sent">
              <p>
                Enter otp code sent to <span class="font-weight-black">+256{{ this.phone }}</span>
                <a
                  variant="plain"
                  @click="otp_sent = !otp_sent"
                >
                  (change)
                </a>
              </p>
              <v-otp-input
                v-model="otpCode"
                outlined
                label="Code"
                length="6"
              ></v-otp-input>
              <v-btn
                block
                color="primary"
                class="mt-6"
                :loading="loading"
                @click="confirmOtpCode"
              >
                Verify Otp
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'auth-register' }">
            Create an account
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text
          v-if="true"
          class="d-flex align-center mt-2"
        >
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions
          v-if="true"
          class="d-flex justify-center"
        >
          <v-btn
            v-if="loginWithEmail"
            block
            color="secondary"
            class="mt-6"
            @click="loginWithEmail = !loginWithEmail"
          >
            SignIn with phone number
            <v-icon color="primary">
              {{ mdiPhone }}
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            block
            color="secondary"
            class="mt-6"
            @click="loginWithEmail = !loginWithEmail"
          >
            SignIn with email and password
            <v-icon color="primary">
              {{ mdiPhone }}
            </v-icon>
          </v-btn>
          <!-- <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn> -->
        </v-card-actions>
        <div id="recapther"></div>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiPhone } from '@mdi/js'
import axios from 'axios'
import { AbilityBuilder } from '@casl/ability'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { initializeApp } from 'firebase/app'
// eslint-disable-next-line no-unused-vars
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { parsePhoneNumber } from 'libphonenumber-js'

const firebaseConfig = {
  apiKey: 'AIzaSyDm8Ju3OMf55e7t_AxKAVOeisr81Va7Nfo',
  authDomain: 'pesasave-647a8.firebaseapp.com',
  projectId: 'pesasave-647a8',
  storageBucket: 'pesasave-647a8.appspot.com',
  messagingSenderId: '1030303546312',
  appId: '1:1030303546312:web:72c4b26ff798ccad6fdefd',
  measurementId: 'G-BSW2DJ2YYQ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// firebase.initializeApp(firebaseConfig)
// const db = firebase.firestore()
const auth = getAuth(app)
const socialLink = [
  {
    icon: mdiPhone,
    color: '#4267b2',
    colorInDark: '#4267b2',
  },
  {
    icon: mdiFacebook,
    color: '#4267b2',
    colorInDark: '#4267b2',
  },
  {
    icon: mdiTwitter,
    color: '#1da1f2',
    colorInDark: '#1da1f2',
  },
  {
    icon: mdiGithub,
    color: '#272727',
    colorInDark: '#fff',
  },
  {
    icon: mdiGoogle,
    color: '#db4437',
    colorInDark: '#db4437',
  },
]
export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      otpCode: '',
      phone: '',
      validPhoneNumber: false,
      otp_sent: false,
      defaultCountryCode: 'UG',
      error: null,
      loginWithEmail: true,
      isPasswordVisible: false,
      loading: false,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      form: {
        body: {
          username: '',
          password: '',
        },
        errors: {},
        remember: false,
        fetchUser: true,
        autoLogin: true,
        staySignedIn: false,
      },
    }
  },
  mounted() {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recapther',
      {
        size: 'invisible',
        // eslint-disable-next-line no-unused-vars
        callback: response => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth,
    )
  },
  methods: {
    errors(res) {
      this.form.errors = res.data.errors // Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]))
    },
    login() {
      this.loading = true
      this.form.errors = null
      this.$auth
        .login({
          url: '/auth/login',
          data: this.form.body,
          redirect: null,
          remember: this.form.remember ? '{"name": "Redirect"}' : null,
          fetchUser: false,
          staySignedIn: true,
        })

        // on success store axios token in store
        .then(res => {
          // log the user from the respons
          const { token } = res.data.data
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          this.$auth.token(null, token, true)

          // get the user from the api
          this.$http.get('me').then(resp => {
            const { data } = resp.data
            this.$auth
              .user({
                id: data.id,
                name: data.name,
                phone: data.phone,
                email: data.email,
                type: 'user',
              })
            this.updateAbility(data.permission)
            this.$router.push({ name: 'dashboard' })
          })
        })
        .then(null, res => {
          this.errors(res.response)
          this.loading = false
        })
    },
    updateAbility(permissions) {
      const { can, rules } = new AbilityBuilder()
      permissions.forEach(permission => {
        can(permission)
      })

      this.$ability.update(rules)
    },
    loginWithPhoneNumber() {
      this.error = null
      this.otpCode = ''
      this.loading = true
      const appVerifier = window.recaptchaVerifier
      const phoneNumber = parsePhoneNumber(this.phone, this.defaultCountryCode).formatInternational()
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then(confirmationResult => {
          // console.log(confirmationResult)
          // console.log('code sent')

          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult
          this.otp_sent = true
          this.loading = false

          // ...
        })
        .catch(error => {
          console.log(error)
          this.error = `Failed to send code. Reason: ${error}`

          // Error; SMS not sent
          // ...
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirmOtpCode() {
      this.loading = true
      const code = this.otpCode
      window.confirmationResult
        .confirm(code)
        .then(result => {
          // User signed in successfully.
          // this.deviceToken = result.user.uid

          result.user.getIdToken().then(async firebaseToken => {
            console.log(firebaseToken)

            // this.setToken(token)
            console.log('otp token verified')
            this.$http.post('auth/verify-firebase-token', {}, {
              headers: {
                Authorization: `Bearer ${firebaseToken}`,
              },
            })

              // on success store axios token in store
              .then(res => {
                // log the user from the respons
                const { token } = res.data.data
                // eslint-disable-next-line dot-notation
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                this.$auth.token(null, token, true)

                // get the user from the api
                this.$http.get('me').then(resp => {
                  const { data } = resp.data
                  this.$auth
                    .user({
                      id: data.id,
                      name: data.name,
                      phone: data.phone,
                      email: data.email,
                      type: 'user',
                    })
                  this.updateAbility(data.permission)
                  this.$router.push({ name: 'dashboard' })
                })
              })
              .then(null, res => {
                this.errors(res.response)
                this.loading = false
              })

            // this.loading = false
          })
        })
        .catch(error => {
          this.loading = false

          // console.log('error1')
          // console.log(error)
          this.error = `Failed to verify code. Reason: ${error}`

          // User couldn't sign in (bad verification code?)
          // ...
        })
        .finally(() => { })
    },

    /**
     * Check if the phone number is valid
     */
    isPhoneNumberValid(value) {
      // if input is valid
      if (value.isValid) {
        // check if phone number already exists
        this.validPhoneNumber = true
      } else {
        this.validPhoneNumber = false
      }
    },
  },
}
</script>
<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
